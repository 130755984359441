.container {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loading {
  width: 100%;
  margin: 40px auto;
}
