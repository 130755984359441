@import '~@astrid/components/dist/scss/utils';

.root {
  width: 100%;
  border-bottom: 2px solid rgba(190, 194, 199, 0.2);
  height: 74px;
  background: var(--header-background);
  font-weight: var(--header-font-weight);
  display: flex;
  flex-direction: row;
  align-items: center;
  nav {
    margin-left: auto;
    > button {
      margin: 0 5px;
    }
  }
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1488px;
  padding: 0 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.controls {
  display: flex;
  flex-direction: row;
  overflow: auto;
  padding: 4px;
}

.logos {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  fill: #ffffff;

  svg {
    @include breakpoint(xs) {
      max-height: 30px;
    }
  }
}

.astridLogo {
  height: 30px;
  fill: #ffffff;
}

.actionBar {
  height: 50px;
  margin: 0 24px;
  display: flex;
  align-items: center;
}

.close {
  height: 80%;
  padding: 0.25rem;
  fill: $cobalt-blue;
  cursor: pointer;
  border-radius: 50%;
  transition: 250ms all;
  background-color: transparent;

  @include breakpoint(xs) {
    margin: 0px;
  }

  & svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.link {
  @include chip-styling;
  margin-left: 1rem;
  height: 2.5rem;
  max-height: 3rem;
  border-radius: 8px;
  width: max-content;

  span {
    width: max-content;
  }

  @include breakpoint(xs) {
    height: 2rem;
  }

  &.active {
    background-color: darken($blue-grey, 8%);
  }

  &:not(.active) svg {
    fill: $black;
  }

  &.noFill {
    svg {
      fill: none;
    }
  }
}
