@import '~@astrid/components/dist/scss/_utils';

.container {
  border-radius: 8px;
  border: 2px solid $light-grey;
  overflow: hidden;
  width: 100%;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

table th:nth-child(3),
table td:nth-child(3) {
  width: 360px;
}

.row {
  th {
    text-align: start;
    border-bottom: 2px solid $light-grey;

    &:not(:last-child) {
      border-right: 2px solid $light-grey;
    }

    h4 {
      margin: 16px;
    }
  }

  &:hover {
    td {
      background-color: rgba($cobalt-blue, 0.1);
    }
  }

  td {
    text-align: start;
    font-family: 'Lexend', sans-serif;
    font-size: 16px;
    padding: 16px;
    border-bottom: 2px solid $light-grey;

    &:not(:last-child) {
      border-right: 2px solid $light-grey;
    }

    a {
      color: $cobalt-blue;
      text-decoration: underline;
    }
  }

  &:last-child td {
    border-bottom: none;
  }
}

.toggle {
  &:global(.toggle-switch) input:checked + label {
    box-shadow: none;
  }
}

.toggleWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
