@import '~@astrid/components/dist/scss/utils';

.studyTimeBeforeUserCreation {
  background-color: $very-light-grey;
}

.leftButton {
  float: left;
}

.rightButton {
  float: right;
}

.container {
  margin: 8px 0 0;
  border: 2px solid $light-grey;
  border-radius: 8px;
  overflow: hidden;

  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    th {
      border-bottom: 2px solid $light-grey;

      &:not(:last-child) {
        border-right: 2px solid $light-grey;
      }
    }

    tr:not(:last-child) {
      border-bottom: 2px solid $light-grey;
    }

    td:not(:last-child) {
      border-right: 2px solid $light-grey;
    }

    td,
    th {
      padding: 16px;
      text-align: left;
    }
  }

  thead {
    tr {
      th {
        font-family: var(--heading-font-family);
        font-size: 18px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $black;
      }
    }
  }

  tbody {
    th,
    td {
      font-size: 16px;
      color: $black;
    }

    a {
      color: $cobalt-blue;

      &:hover {
        text-decoration: underline;
      }
    }

    tr {
      &:hover {
        background-color: $very-light-grey;
      }
    }

    td {
      height: 56px;
      padding: 0 16px;
      user-select: none;
      position: relative;
    }
  }
}

.metricSelect {
  margin-left: auto;
  margin-top: 16px;
  max-width: 400px;

  :global(.custom-select__display) {
    background-color: #fafafa;
    font-size: 1.2rem;

    & > div {
      height: auto;
    }
  }

  svg {
    width: 28px;
    height: 28px;
  }
}

.lastUpdated {
  margin-top: 32px;
}
