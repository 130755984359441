@import '~@astrid/components/dist/scss/utils';

.root {
  width: 100%;
  min-height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 0;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: auto;
}

.headline {
  flex: 1 0 auto;
}

.filtersContainer {
  width: 400px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  & > div[data-role='wrapper'] {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  :global(.custom-select__options) {
    max-height: 600px;
  }
}

.classSelect {
  margin: 0 0 16px;
  padding: 2px;
  :global(.custom-select__display) {
    background-color: #fafafa;
    font-size: 1.2rem;

    & > div {
      height: auto;
    }
  }

  svg {
    width: 28px;
    height: 28px;
  }
}

.card {
  margin-top: 24px;
  width: 50%;
}
