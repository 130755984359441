@import '~@astrid/components/dist/scss/globals';

@font-face {
  font-family: 'Inter';
  src: local('InterMedium'), url('./shared/assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: local('InterBold'), url('./shared/assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: local('InterBlack'), url('./shared/assets/fonts/Inter/Inter-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'AnekBangla';
  src: local('AnekBanglaSemiBold'), url('./shared/assets/fonts/AnekBangla/AnekBangla-SemiBold.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'TrinityInterstate';
  src: local('TrinityInterstateLight'),
    url('./shared/assets/fonts/TrinityInterstate/TrinityInterstate-Light.woff2') format('woff2'),
    url('./shared/assets/fonts/TrinityInterstate/TrinityInterstate-Light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'TrinityInterstate';
  src: local('TrinityInterstateRegular'),
    url('./shared/assets/fonts/TrinityInterstate/TrinityInterstate-Regular.woff2') format('woff2'),
    url('./shared/assets/fonts/TrinityInterstate/TrinityInterstate-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'TrinityInterstate';
  src: local('TrinityInterstateBold'),
    url('./shared/assets/fonts/TrinityInterstate/TrinityInterstate-Bold.woff2') format('woff2'),
    url('./shared/assets/fonts/TrinityInterstate/TrinityInterstate-Bold.woff') format('woff');
  font-weight: 700;
}
