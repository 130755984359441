.container {
  width: 100%;
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
  display: flex;
}

.recoverPasswordButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.captcha {
  display: flex;
  justify-content: center;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
