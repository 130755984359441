@import '~@astrid/components/dist/scss/utils';

.wrapper {
  display: flex;
  flex-direction: row;
  max-width: 970px;
  background-color: #fafafa;
  justify-items: flex-start;
  padding: 40px 32px;
  border-radius: 10px;
  align-self: center;
  margin: 32px 8px;
  flex: 1 1 auto;
  max-height: calc(100% - 146px);
  width: 100%;

  @include breakpoint(xs) {
    padding: 24px 16px;
    margin: 8px 8px;
    max-height: none;
    width: auto;
    flex: 1 0 auto;
    flex-direction: column;
  }
}

.upload {
  margin-right: 70px;
  @include breakpoint(xs) {
    margin-right: 0;
    margin-bottom: 20px;
    width: 80px;
    height: 80px;
    align-self: center;

    label {
      width: 80px;
      height: 80px;
    }

    img {
      width: 80px;
      height: 80px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  & > h3 {
    margin-top: 0;
  }

  & > h4 {
    margin-bottom: 16px;
  }
}

.security {
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    margin: 10px 0;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px 0 0;
    border-bottom: 1px solid rgba(black, 0.1);
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: rgba(black, 0.1);
    }
  }
}

.logout {
  align-self: flex-end;
  margin-top: 18px;
}

.changePasswordForm {
  max-width: 440px;
  width: auto;
}
