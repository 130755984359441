@import '~@astrid/components/dist/scss/utils';

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @include breakpoint(xs) {
    flex-direction: column;
  }

  & > div {
    height: auto;
    width: calc(50% - 16px);

    @include breakpoint(xs) {
      width: auto;
    }
  }
}

.card {
  margin-bottom: 24px;
  box-shadow: none;
}
