@import '~@astrid/components/dist/scss/colors';
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.clickable {
  cursor: pointer;
}

.cover {
  width: 100%;
  height: 246px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  border-bottom: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 6px;
}

.points {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 14px;

  svg {
    width: 18px;
    height: 18px;
    margin-bottom: 2px;
    fill: $maize;
  }
}
