@import '~@astrid/components/dist/scss/utils';

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  justify-content: center;
  transition: 230ms all;
  overflow-y: auto;
  background-color: white;

  h2 {
    margin: 0;
  }

  & > button {
    display: inline-flex;
    margin-top: 20px;
    svg {
      width: 22px;
      height: 22px;
      margin-left: 6px;
    }
  }
}

.leftGraphic {
  position: absolute;
  bottom: 150px;
  width: 550px;
  height: auto;
  left: -60px;
  @include breakpoint(lg) {
    display: none;
  }
}

.rightGraphic {
  position: absolute;
  right: 50px;
  bottom: 130px;
  width: 300px;
  height: auto;
  @include breakpoint(lg) {
    display: none;
  }
}
