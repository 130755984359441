.container {
  flex: 1;
  overflow: auto;
  overflow-x: hidden;
}

.inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 0 24px;
}
