@import '~@astrid/components/dist/scss/utils';

.container {
  margin: 8px 0 0;
  border: 2px solid $light-grey;
  border-radius: 8px;
  overflow: auto;

  table {
    border-collapse: collapse;
    width: 100%;

    th {
      border-bottom: 2px solid $light-grey;

      &:not(:last-child) {
        border-right: 2px solid $light-grey;
      }
    }

    tr {
      border-bottom: 2px solid $light-grey;
    }

    td:not(:last-child) {
      border-right: 2px solid $light-grey;
    }

    td,
    th {
      padding: 16px;
      text-align: left;
    }
  }

  input {
    font: inherit;
    width: 100%;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    border: none !important;
    outline: none;
  }

  thead {
    tr {
      &:first-child {
        th {
          font-family: var(--heading-font-family);
          font-size: 18px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: $black;
        }
      }

      &:last-child {
        th,
        p {
          font-size: 16px;
          color: $black;
          white-space: nowrap;
          text-align: left;
          font-weight: normal;
        }
      }
    }
  }

  tbody {
    th,
    td {
      font-size: 16px;
      color: $black;
    }

    a {
      color: $cobalt-blue;
      text-decoration: underline;
    }

    tr:not(.empty) {
      &:hover {
        background-color: $very-light-grey;
      }
    }

    td {
      height: 56px;
      padding: 0 16px;
      user-select: none;
      position: relative;
      &.hasEdit {
        background-color: $very-light-grey;
        color: rgba(10, 10, 10, 0.3);
      }
    }

    .total {
      border-bottom: none;

      td {
        font-weight: bold;
        background-color: #fafafa;
      }
    }
  }
}

.inputWithUnits {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    margin-left: 4px;
  }
}

.cellActions {
  float: right;
  display: flex;
  flex-direction: row;
  margin: 0 -5px;

  button {
    margin: 0 5px;
  }
}

.editMode {
  tbody {
    th,
    td {
      &:not(.isEdited) {
        opacity: 0.5;
      }
    }
  }
}

.columnComment {
  color: $grey;
}

.message {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: $text-secondary;

  .spinner {
    color: $text-secondary;
  }
}

.progressCell {
  background-color: rgba(207, 227, 240, 0.4);
}

.effortCell {
  background-color: rgba(250, 228, 236, 0.4);
}

.toggleWrapper {
  min-height: 50px;
}
