.modalTitle {
  margin: 30px 0px 16px 0;
}

.modalButtons {
  display: flex;
  flex-direction: row;

  & > button {
    margin: 0 10px;
  }
}

.copyButtons {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.copyIcon {
  width: 16px !important;
  height: 16px !important;
}

.copyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
