@import '~@astrid/components/dist/scss/utils';

:root {
  --body-font-family: 'Inter', sans-serif;
  --heading-font-family: 'AnekBangla', sans-serif;
  --header-background: #000000;
  --header-font-weight: normal;
}

.brand-trinity {
  --body-font-family: 'TrinityInterstate', sans-serif;
  --heading-font-family: 'TrinityInterstate', sans-serif;
  --header-background: #50144b;
  --header-font-weight: bold;
}

/*
  Need to override fonts with !important because global css file in components folder uses !important
*/
.theme {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  font-family: var(--body-font-family) !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  th {
    font-family: var(--heading-font-family) !important;
  }
}
