@import '~@astrid/components/dist/scss/utils';

.container {
  width: 100%;
  min-height: 100%;
  max-width: 1440px;
  margin: 40px auto;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;

  & > div[data-role='wrapper'] {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.classSelect {
  width: 300px;
  margin: 0 0 8px;
}

.message {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: $text-secondary;

  .spinner {
    color: $text-secondary;
  }
}

.lastUpdated {
  margin-bottom: 8px;
  display: block;
}

.toggle {
  margin: 0;
  margin-bottom: 40px;
}
