@import '~@astrid/components/dist/scss/utils';

.tooltip {
  border-radius: 8px;
  background-color: $black;
  padding: 1px 8px;
  p {
    font-family: $lexendFont;
    font-size: 12px;
    line-height: 6px;
    color: #ffffff;
  }
}
