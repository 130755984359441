.difficulty {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: 0 0 20px;

  h3 {
    margin: 0;
  }

  svg {
    width: 30px;
    height: 30px;
    padding: 4px;
    border-radius: 8px;
    margin-right: 10px;
    background-color: black;
    fill: white;
  }
}

.difficultySection {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
}

.carouselContainer {
  max-width: 100%;
  height: 300px;
}
