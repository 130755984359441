@import '~@astrid/components/dist/scss/utils';

.container {
  width: 100%;
  min-height: 100%;
  max-width: 1440px;
  margin: 40px auto;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;

  & > div[data-role='wrapper'] {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.lastUpdated {
  margin-bottom: 8px;
  display: block;
}

.classSelect {
  width: 300px;
  margin: 0;

  :global(.custom-select__options) {
    max-height: 600px;
  }

  :global(.custom-select__display) {
    background-color: #fafafa;

    font-size: 1.2rem;

    & > div {
      height: auto;
    }
  }

  svg {
    width: 36px;
    height: 36px;
  }
}

.message {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: $text-secondary;

  .spinner {
    color: $text-secondary;
  }
}

.actions {
  display: flex;
  gap: 24px;
  align-items: flex-end;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.toggle {
  margin: 0;
}
