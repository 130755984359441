@import '~@astrid/components/dist/scss/utils';

.container {
  max-width: 550px;
  min-height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;

  form {
    width: 100%;
  }
}

.wrapper {
  display: flex;
  width: 100%;
  max-width: 700px;
  flex-direction: column;
}

.confirm {
  margin-top: 16px;
}

.questionText {
  margin: 30px 0;
}

.importButton {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 8px;

  max-height: 200px;
  overflow: auto;

  label {
    display: flex;
    align-items: center;
  }
}

.labelContent {
  display: flex;
  flex-direction: column;
}
