@import '~@astrid/components/dist/scss/utils';

.forgot {
  cursor: pointer;
  margin: 0;
  margin-top: 18px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

.footer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 10px;
  flex-direction: column;
  text-align: center;

  a {
    white-space: nowrap;
  }
}

.captcha {
  margin-top: 10px;
}
