@import '~@astrid/components/dist/scss/utils';

.book-factory {
  width: calc(100% + 48px);
  margin-left: -24px;
  height: 100%;
  display: flex;
  flex-grow: 1;
  position: relative;
  left: 0;

  &__navigation {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    pointer-events: none;

    @include breakpoint(xs) {
      height: auto;
      bottom: 50px;
    }

    button {
      height: 100%;
      width: 80px;
      background-color: transparent;
      padding: 0;
      margin: 0;
      cursor: pointer;
      display: flex;
      flex-direction: column;

      &:focus svg {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
      }
    }

    svg {
      flex: 1;
      width: 100%;
      pointer-events: all;
      padding: 0 15px;
      transition: 300ms all;

      &:hover {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
      }
    }
  }

  &__icon {
    &--rotated {
      transform: rotate(180deg);
    }
  }

  &__progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: lighten($cobalt-blue, 60%);
    z-index: 10;

    & > div {
      height: 100%;
      background-color: $cobalt-blue;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      transition: 800ms all;
    }
  }
}
