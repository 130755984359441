.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 24px 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    text-align: left;
    align-self: flex-start;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  margin-bottom: 24px;

  h1 {
    margin: 0;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 6px;
    }
  }
}

.modal {
  p {
    text-align: center;
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    button {
      margin: 0 16px;
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}
