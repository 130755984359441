@import '~@astrid/components/dist/scss/utils';

.card {
  box-shadow: none;
  overflow: auto;
  & > * {
    padding: 0;
  }
}

.table tr:last-child td {
  border-bottom: 0;
}

.headerCell {
  font-weight: bold;
  user-select: none;
  font-size: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: fit-content;

  & > svg {
    position: absolute;
    width: 20px;
    height: 20px;
    right: -20px;
    transition: 230ms all;
    fill: $text-secondary;
  }

  &__actions {
    @extend .headerCell;
    float: right;
    cursor: auto;
  }

  &__disabled {
    @extend .headerCell;
    cursor: auto;
  }
}

.toolbar {
  padding: 20px 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  h4 {
    margin: 0;
  }

  & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__item {
    @include chip-styling(true);
    margin-left: 0.75rem;
    background-color: #fafafa;
    &:hover {
      background-color: darken(#fafafa, 10%);
    }
    &:disabled {
      opacity: 0.4;
    }
  }

  &__item_share {
    @extend .toolbar__item;
    background-color: $black;
    color: white;
    svg {
      fill: white;
    }
    &:hover {
      background-color: $black;
    }
  }

  &__popover {
    padding: 10px 5px;
    &__item {
      @include chip-styling(true);
      justify-content: flex-start;
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }
}

.row {
  & > td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 130px;

    &:hover {
      text-overflow: clip;
      white-space: normal;
      word-break: break-all;
    }
  }

  p {
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-overflow: clip;
      white-space: normal;
      word-break: break-all;
    }
  }
}

.noStudentsInfo {
  width: 100%;
  color: $text-secondary;
  text-align: center;
  padding: 24px 4px;
  margin: 0;
}

.verticalCell {
  display: flex;
  flex-direction: column;
  margin: 0;
}
