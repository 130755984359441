@import '~@astrid/components/dist/scss/utils';

.navigationContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
  overflow-x: auto;
}

.navigation {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: flex-end;
}

.tab {
  padding: 16px;
  border-bottom: 4px solid transparent;
  cursor: pointer;

  &:hover {
    border-color: #e2e2e2;
  }
}

.activeTab {
  border-color: #e2e2e2;
}

.content {
  margin: 32px 0;
}
